import React, { useEffect } from "react";
import useLocalStorage from "use-local-storage";

import "./theme.scss";
import { ReactComponent as MoonIcon } from "./../../../assets/icons/m1.svg";
import { ReactComponent as SunIcon } from "./../../../assets/icons/s1.svg";

const Theme = () => {
  const [theme, setTheme] = useLocalStorage("theme", "light");
  const toggleThemeOnClick = () => {
    setTheme(theme === "dark" ? "light" : "dark");
  };
  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);
  return (
    <div
      id="ThemeToggleContainer"
      className={`px-1 ${theme}`}
      onClick={toggleThemeOnClick}
      role="button"
      // style={{ transform: "scale(0.5)" }}
    >
      <div className="moon-child w-100 d-flex justify-content-end">
        <MoonIcon />
      </div>
      <div className="sun-child w-100 d-flex justify-content-start">
        <SunIcon />
      </div>
    </div>
  );
};

export default Theme;
