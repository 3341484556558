import axios from "axios";
import { FEED_API_PATH } from "./api.constant";

export const fetchFeed = async (page) => {
  let authResult = await axios.get(FEED_API_PATH.FEED + page);
  if (authResult.status === 200) {
    return authResult.data.data;
  } else {
    throw new Error(authResult.error);
  }
};
