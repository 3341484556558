/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import UserIcon from "../UserIcon/UserIcon";
import PostMetadata from "./PostMetadata/PostMetadata";

import "./post.scss";
import { toast } from "react-toastify";
import { getPost } from "../../API/post.api";
import { LinearProgress, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import useLocalStorage from "use-local-storage";

const Post = ({ post, postId }) => {
  const [user] = useLocalStorage("user", null);
  const [localPost, setLocalPost] = useState(post);
  const [firstRun, setFirstRun] = useState(true);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!firstRun || postId) {
      updatePost(postId === null);
    } else {
      setFirstRun(false);
    }
  }, [user]);

  const updatePost = async (showUpdating = true) => {
    try {
      showUpdating && setLoading(true);
      const latestPostData = await getPost(post?.postId || postId);
      setLocalPost(latestPostData);
    } catch (error) {
      toast.error(error);
    } finally {
      showUpdating && setLoading(false);
    }
  };

  return localPost ? (
    <>
      <div className="post w-100 mb-3 d-flex p-1 pe-2 p-md-2 px-md-1 position-relative">
        <div className="col-2 col-md-1 d-flex justify-content-center">
          <UserIcon username={localPost["username"]} />
        </div>
        <div className="col-10 col-md-11 d-flex flex-column">
          <div
            className="post-username"
            style={{ fontWeight: "700", fontSize: "0.8rem" }}
          >
            {localPost["username"]}
          </div>
          <div
            className="post-content"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(localPost["content"]),
            }}
            onClick={() => {
              if (post) {
                navigate(`/home/post/${post.postId}`);
              }
            }}
            role="button"
          ></div>
          <PostMetadata post={localPost} updatePost={updatePost} />
        </div>
        {loading ? (
          <LinearProgress
            className="w-100 position-absolute"
            style={{ bottom: 0 }}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  ) : (
    <>
      <Skeleton
        variant="rectangular"
        height={200}
        className="mb-3"
        sx={{ borderRadius: "5px" }}
      />
    </>
  );
};

export default Post;
