import React, { useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { useAuthContext } from "../../../custom/providers/AuthProvider";
import { useDispatch } from "react-redux";
import { SetShowSignIn } from "../../../redux/globalReducer";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LoginIcon from "@mui/icons-material/Login";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LogoutIcon from "@mui/icons-material/Logout";
import "./userMenu.scss";
import Theme from "../../theme/Theme";
import UserIcon from "../../UserIcon/UserIcon";

const UserMenu = () => {
  const { isAuthenticated, signOut, user } = useAuthContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div id="UserMenu">
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {isAuthenticated ? (
          <UserIcon
            username={
              user["tokenDecoded"]["username"] || user["tokenDecoded"]["email"]
            }
            size={"25px"}
          />
        ) : (
          <AccountCircleIcon className="user-logo" />
        )}
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
          disablePadding: true,
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        {isAuthenticated
          ? [
              <MenuItem
                onClick={handleClose}
                key="profile"
                className="d-flex justify-content-between"
              >
                <span>Profile</span>
                <AccountBoxIcon />
              </MenuItem>,
              <MenuItem key="Theme" className="d-flex justify-content-between">
                Theme <Theme />
              </MenuItem>,
              <MenuItem
                onClick={() => {
                  signOut(null);
                  handleClose();
                }}
                key="Logout"
                className="d-flex justify-content-between"
              >
                <span>Sign Out</span>
                <LogoutIcon />
              </MenuItem>,
            ]
          : [
              <MenuItem
                onClick={() => {
                  dispatch(SetShowSignIn(true));
                  handleClose();
                }}
                key="Sign In"
                className="d-flex justify-content-between"
              >
                <span>Sign In</span>
                <LoginIcon />
              </MenuItem>,
              <MenuItem key="Theme" className="d-flex justify-content-between">
                Theme <Theme />
              </MenuItem>,
            ]}
      </Menu>
    </div>
  );
};

export default UserMenu;
