/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Collapse, Skeleton } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import { fetchFeed } from "../../../API/feed.api";
import Post from "../../../components/Post/Post";
import { TransitionGroup } from "react-transition-group";
import useLocalStorage from "use-local-storage";
import axios from "axios";

const Feed = () => {
  const [user] = useLocalStorage("user", null);
  const [feedData, setFeedData] = useState([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hasMorePage, setHasMorePage] = useState(true);

  useEffect(() => {
    loadFeed(page + 1);
  }, []);

  const loadFeed = async (pageNumber = 1) => {
    try {
      axios.defaults.headers.common["Authorization"] = user
        ? "Bearer " + user.token
        : null;
      setPage(pageNumber);
      setLoading(true);
      const newFeedPageData = await fetchFeed(pageNumber);
      const newFeedData = [...feedData, ...newFeedPageData];
      if (pageNumber === 1) {
        setFeedData(newFeedPageData);
      } else {
        setFeedData(newFeedData);
      }

      setHasMorePage(newFeedPageData.length > 0);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const LOADING_POST_SKELETON = (
    <>
      <TransitionGroup>
        <Collapse key={"pl-1"}>
          <Skeleton
            variant="rectangular"
            height={118}
            className="mb-3"
            sx={{ borderRadius: "5px" }}
          />
        </Collapse>
        <Collapse key={"pl-2"}>
          <Skeleton
            variant="rectangular"
            height={118}
            className="mb-3"
            key={"pl-2"}
            sx={{ borderRadius: "5px", opacity: "0.8" }}
          />
        </Collapse>
        <Collapse key={"pl-3"}>
          <Skeleton
            variant="rectangular"
            height={118}
            className="mb-3"
            key={"pl-3"}
            sx={{ borderRadius: "5px", opacity: "0.6" }}
          />
        </Collapse>
      </TransitionGroup>
    </>
  );

  return (
    <div>
      <InfiniteScroll
        dataLength={feedData.length}
        next={() => {
          loadFeed(page + 1);
        }}
        hasMore={hasMorePage}
        className="position-relative"
        style={{ overflow: "reset" }}
        loader={LOADING_POST_SKELETON}
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
        scrollableTarget="body"
      >
        <TransitionGroup>
          {feedData.map((post, index) => (
            <Collapse key={post["postId"] + index}>
              <Post
                post={post}
                index={index}
                key={post["postId"]}
              />
            </Collapse>
          ))}
        </TransitionGroup>
        {loading && feedData.length === 0 ? (
          <>{LOADING_POST_SKELETON}</>
        ) : (
          <></>
        )}
      </InfiniteScroll>
    </div>
  );
};

export default Feed;
