import { Avatar } from "@mui/material";
import React from "react";

import "./userIcon.scss";

const UserIcon = ({ username, size = "30px" }) => {
  const stringToColour = (str) => {
    let hash = 0;
    str.split("").forEach((char) => {
      hash = char.charCodeAt(0) + ((hash << 5) - hash);
    });
    let colour = "#";
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      colour += value.toString(16).padStart(2, "0");
    }
    return colour;
  };
  const color = stringToColour(username);
  return (
    <div
      style={{
        padding: "1px",
        border: `1px solid ${color}`,
        width: "fit-content",
        height: "fit-content",
        borderRadius: size,
      }}
    >
      <Avatar
        sx={{
          bgcolor: color,
          textTransform: "uppercase",
          width: `${size}`,
          height: `${size}`,
          fontSize: "1rem",
        }}
      >
        {username
          .split(" ")
          .map((n) => n[0])
          .join(".")}
      </Avatar>
    </div>
  );
};

export default UserIcon;
