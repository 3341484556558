const BASE = process.env.REACT_APP_BASE_PATH || "";

export const AUTH_API_PATH = {
  LOGIN: BASE + "/auth/login",
  LOGOUT: BASE + "/auth/logout",
  SIGNUP: BASE + "/auth/signup",
};

export const FEED_API_PATH = {
  FEED: BASE + "/post/getFeed?page=",
};

export const POST_API_PATH = {
  SUBMIT: BASE + "/post",
  GET_POST: (postId) => BASE + `/post/get/${postId}`,
  REACT: (postId, type) => BASE + `/post/${postId}/react?type=${type}`,
};

export const COMMENT_API_PATH = {
  GET_COMMENTS: (postId)=> BASE + `/post/get/${postId}/comments`,
  SUBMIT: (postId) => BASE + `/post/${postId}/comment`,
  GET_COMMENT: (postId) => BASE + `/post/get/${postId}`,
  REACT: (postId, type) => BASE + `/post/${postId}/react?type=${type}`,
};

