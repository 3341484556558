import React, { useState, useEffect } from "react";
// import AnimatedNumber from "animated-number-react";

const CustomAnimatedNumbers = ({ value, toFixedNumber = 0 }) => {
  const [localValue, setLocalValue] = useState(value);
  useEffect(() => {
    setLocalValue(value);
  }, [value]);
  return (
    <>
      {/* <AnimatedNumber
        value={localValue}
        formatValue={(v) => v.toFixed(toFixedNumber)}
        duration={200}
      /> */}
      {localValue}
    </>
  );
};

export default CustomAnimatedNumbers;
