import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showSignIn: false,
  showSignUp: false,
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    SetShowSignIn: (state, action) => {
      state.showSignIn = action.payload;
    },
    SetShowSignUp: (state, action) => {
      state.showSignUp = action.payload;
    },
  },
});

export const { SetShowSignIn, SetShowSignUp } = globalSlice.actions;

export default globalSlice.reducer;
