import React, { useEffect } from "react";
import { ReactComponent as BrandLogoDark } from "./../../../assets/icons/brandDark.svg";
import { ReactComponent as BrandLogoLight } from "./../../../assets/icons/brandLight.svg";
import useLocalStorage from "use-local-storage";
import UserMenu from "./userMenu/UserMenu";

import "./header.scss";

const Header = () => {
  const [theme] = useLocalStorage("theme", "light");
  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);
  return (
    <div
      id="header"
      className="w-100 d-flex justify-content-between align-items-center pt-1 pb-0 px-2 px-md-1 position-sticky top-0"
    >
      <div>
        {theme === "dark" ? (
          <BrandLogoLight style={{ width: "200px", height: "30px" }} />
        ) : (
          <BrandLogoDark style={{ width: "200px", height: "30px" }} />
        )}
      </div>
      <div className="d-flex gap-1 align-items-center">
        <UserMenu />
      </div>
    </div>
  );
};

export default Header;
