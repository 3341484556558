import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import Diversity2Icon from "@mui/icons-material/Diversity2";
import SettingsIcon from "@mui/icons-material/Settings";

import "./homeMenu.scss";

const HomeMenu = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div
      id="HomeMenu"
      className="d-flex flex-column align-items-center align-items-md-start py-3"
    >
      <div
        className={`home-menu-item d-flex align-items-center justify-content-center ${
          location.pathname === "/home/feed" ? "active" : ""
        }`}
        role="button"
        onClick={() => navigate("/home/feed")}
      >
        <DynamicFeedIcon />
        <span className="d-block d-md-block">&nbsp;Feed</span>
      </div>
      <div
        className={`home-menu-item d-flex align-items-center ${
          location.pathname === "/home/groups" ? "active" : ""
        }`}
        role="button"
        onClick={() => navigate("/home/groups")}
      >
        <Diversity2Icon />
        <span className="d-block d-md-block">&nbsp;Groups</span>
      </div>
      <div
        className={`home-menu-item d-flex align-items-center ${
          location.pathname === "/home/settings" ? "active" : ""
        }`}
        role="button"
        onClick={() => navigate("/home/settings")}
      >
        <SettingsIcon />
        <span className="d-block d-md-block">&nbsp;Settings</span>
      </div>
    </div>
  );
};

export default HomeMenu;
