/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import UserIcon from "../../UserIcon/UserIcon";

import "./comment.scss";
import { toast } from "react-toastify";
import { getPost } from "../../../API/post.api";
import { LinearProgress, Skeleton } from "@mui/material";
import DOMPurify from "dompurify";
import CommentMetadata from "./commentMetadata.js/CommentMetadata";
import Comments from "../Comments";
import NewPost from "../../NewPost/NewPost";
import useLocalStorage from "use-local-storage";

const Comment = ({ post, postId }) => {
  const [user] = useLocalStorage("user", null);
  const [firstRun, setFirstRun] = useState(true);
  const [localPost, setLocalPost] = useState(post);
  const [loading, setLoading] = useState(false);
  const [showChildComments, setShowChildComments] = useState(false);
  const [showReplyComment, setShowReplyComment] = useState(false);
  useEffect(() => {
    if (!firstRun) {
      updatePost(false);
    } else {
      setFirstRun(false);
    }
  }, [user]);

  const updatePost = async (showUpdating = true) => {
    try {
      showUpdating && setLoading(true);
      const latestPostData = await getPost(post?.postId || postId);
      setLocalPost(latestPostData);
    } catch (error) {
      toast.error(error);
    } finally {
      showUpdating && setLoading(false);
    }
  };

  return localPost ? (
    <div
      style={{ background: "var(--highlight-background)", borderRadius: "5px" }}
      className="mt-2"
    >
      <div className="post w-100 d-flex  position-relative">
        <div className="col-1 col-md-1 d-flex flex-column align-items-center justify-content-start">
          <div className="mt-3">
            <UserIcon username={localPost["username"]} size={"20px"} />
          </div>
          {(showChildComments || showReplyComment) && (
            <div
              style={{
                borderLeft: "3px solid var(--highlight-color)",
                width: "3px",
              }}
              className="flex-grow-1 mt-2"
            >
              &nbsp;
            </div>
          )}
        </div>
        <div className="col-10 col-md-11 d-flex flex-column mb-3 p-1 pe-2 p-md-2 px-md-1">
          <div
            className="post-username"
            style={{ fontWeight: "700", fontSize: "0.8rem" }}
          >
            {localPost["username"]}
          </div>
          <div
            className="post-content"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(localPost["content"]),
            }}
          ></div>
          <CommentMetadata
            post={localPost}
            updatePost={updatePost}
            setShowChildComments={setShowChildComments}
            showChildComments={showChildComments}
            showReplyComment={showReplyComment}
            setShowReplyComment={setShowReplyComment}
          />
        </div>
        {loading ? (
          <LinearProgress
            className="w-100 position-absolute"
            style={{ bottom: 0 }}
          />
        ) : (
          <></>
        )}
      </div>
      {showReplyComment ? (
        <div className="d-flex">
          <div className="col-1 d-flex justify-content-center">
            <div
              style={{
                borderLeft: "3px solid var(--highlight-color)",
                width: "3px",
              }}
              className="h-100 pb-5"
            >
              &nbsp;
            </div>
          </div>
          <div className="flex-grow-1">
            <NewPost
              postId={postId || post.postId}
              posted={() => updatePost()}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
      {showChildComments ? (
        <>
          <Comments
            postId={postId || post.postId}
            commentsCount={localPost["comments"]}
          />
        </>
      ) : (
        <></>
      )}
    </div>
  ) : (
    <>
      <Skeleton
        variant="rectangular"
        height={200}
        className="mb-3"
        sx={{ borderRadius: "5px" }}
      />
    </>
  );
};

export default Comment;
