import React, { useEffect, useState } from "react";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { CircularProgress, IconButton } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { formatDistanceToNow, format } from "date-fns";
import { reactToPost } from "../../../../API/post.api";
import RequireLogin from "../../../RequireLogin/RequireLogin";
import ReplyIcon from '@mui/icons-material/Reply';

import { toast } from "react-toastify";

import "./commentMetadata.scss";
import CustomAnimatedNumbers from "../../../customAnimatedNumbers/CustomAnimatedNumbers";

const CommentMetadata = ({ post, updatePost, setShowChildComments, showChildComments, setShowReplyComment, showReplyComment }) => {
  const [loadingReaction, setLoadingReaction] = useState(false);

  useEffect(() => {
    setLoadingReaction(false);
  }, [post]);

  const addReaction = async (type) => {
    try {
      setLoadingReaction(true);
      await reactToPost(post.postId, type);
    } catch (error) {
      toast.error(error);
    } finally {
      // setLoadingReaction(false);
      updatePost(false);
    }
  };

  return (
    <div
      className="post-metadata w-100 d-flex justify-content-between"
      style={{ fontSize: "0.8rem" }}
    >
      <div className="post-metadata-tile d-flex align-items-center">
        <IconButton
          className="post-metadata-tile-button"
          onClick={() => {
            setShowChildComments(!showChildComments);
          }}
        >
          <ChatBubbleOutlineIcon className="post-metadata-tile-button-icon" />
        </IconButton>
        <span className="post-metadata-tile-button-text">
          &nbsp;
          <CustomAnimatedNumbers value={post["comments"]} />
        </span>
      </div>

      <div className="post-metadata-tile d-flex align-items-center">
        <IconButton
          className="post-metadata-tile-button"
          onClick={() => {
            setShowReplyComment(!showReplyComment);
          }}
        >
          <ReplyIcon className="post-metadata-tile-button-icon" />
        </IconButton>
        
      </div>

      <div className="post-metadata-tile d-flex align-items-center">
        <RequireLogin>
          <IconButton
            className="post-metadata-tile-button"
            onClick={() => addReaction(post["reactedByYou"] ? 0 : 1)}
            disabled={loadingReaction}
          >
            {loadingReaction ? (
              <CircularProgress size="1rem" />
            ) : (
              <>
                {post["reactedByYou"] === true ? (
                  <FavoriteIcon
                    className="post-metadata-tile-button-icon"
                    sx={{ color: "red !important" }}
                  />
                ) : (
                  <FavoriteBorderIcon className="post-metadata-tile-button-icon" />
                )}
              </>
            )}
          </IconButton>
        </RequireLogin>
        <span className="post-metadata-tile-button-text">
          <CustomAnimatedNumbers value={post["reactions"]} />
        </span>
      </div>

      <div className="d-none post-metadata-tile d-md-flex align-items-center">
        <span className="post-metadata-tile-button-text">
          {formatDistanceToNow(new Date(post["date"]))}&nbsp;ago
        </span>
      </div>

      <div className="post-metadata-tile d-flex align-items-center">
        <span className="post-metadata-tile-button-text">
          {format(new Date(post["date"]), "hh:mm:ss")}&nbsp;•&nbsp;
          {format(new Date(post["date"]), "dd LLL yyyy")}
        </span>
      </div>
    </div>
  );
};

export default CommentMetadata;
