import React from "react";
import { useParams } from "react-router-dom";
import Post from "../../../components/Post/Post";
import Comments from "../../../components/comments/Comments";
import NewPost from "../../../components/NewPost/NewPost";

const PostDetails = () => {
  const params = useParams();
  

  return (
    <div>
      <Post postId={params.postId} />
      <NewPost postId={params.postId} />
      <Comments postId={params.postId} />
    </div>
  );
};

export default PostDetails;
