import React from "react";
import { useAuthContext } from "../../custom/providers/AuthProvider";
import { useDispatch } from "react-redux";
import { SetShowSignIn } from "../../redux/globalReducer";

const RequireLogin = ({ children }) => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuthContext();

  return (
    <>
      {isAuthenticated ? (
        <>{children}</>
      ) : (
        <span
          onClick={(event) => {
            dispatch(SetShowSignIn(true));
            event.stopPropagation();
          }}
          role="button"
        >
          <span style={{ pointerEvents: "none" }}>{children}</span>
        </span>
      )}
    </>
  );
};
export default RequireLogin;
