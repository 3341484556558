/* eslint-disable react-hooks/exhaustive-deps */
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Header from "./components/header/Header";
import SignIn from "./components/signIn/SignIn";
import Home from "./pages/Home/Home";
import SignUp from "./components/signUp/SignUp";
import useLocalStorage from "use-local-storage";
import { useEffect } from "react";
import axios from "axios";
import { useAuthContext } from "./custom/providers/AuthProvider";

function App() {
  const [user] = useLocalStorage("user", null);
  const { checkExpiry } = useAuthContext();

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = user
      ? "Bearer " + user.token
      : null;
    // axios.defaults.headers.common["authorization"] = user
    //   ? "Bearer " + user.token
    //   : null;
    checkExpiry();
    // axios.defaults.headers.common["token"] = user ? user.token : null;
  }, [user]);

  return (
    <div id="App" className="container position-relative">
      <Header />
      <Router>
        <Routes>
          <Route path="/home/*" element={<Home />} />
          <Route path="*" element={<Navigate to="/home" />} />
        </Routes>
      </Router>
      <SignIn />
      <SignUp />
    </div>
  );
}

export default App;
