import { Button, Dialog, DialogTitle, TextField } from "@mui/material";
import React, { useState } from "react";
import { z } from "zod";
import * as meaningfulUserGenerator from "meaningful-string";

import { useDispatch, useSelector } from "react-redux";
import { SetShowSignIn, SetShowSignUp } from "../../redux/globalReducer";
import CircularProgress from "@mui/material/CircularProgress";
import { ReactComponent as BrandLogoDark } from "./../../../assets/icons/brandDark.svg";
import { ReactComponent as BrandLogoLight } from "./../../../assets/icons/brandLight.svg";

import "./signUp.scss";
import { toast } from "react-toastify";
import { useAuthContext } from "../../custom/providers/AuthProvider";
import useLocalStorage from "use-local-storage";

const SignUp = () => {
  const [theme] = useLocalStorage("theme", "light");
  const { signUp } = useAuthContext();
  const showSignUp = useSelector((state) => state.global.showSignUp);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [inputData, setInputData] = useState({
    email: "",
    password: "",
    confirm: "",
  });

  const USER = z
    .object({
      email: z.string().email(),
      password: z.string().min(4),
      confirm: z.string().min(4),
    })
    .refine((data) => data.password === data["confirm"], {
      message: "Passwords don't match",
      path: ["confirm"], // path of error
    });

  const handleClose = () => {
    if (!loading) {
      dispatch(SetShowSignUp(false));
    }
  };

  const handleInputDataChange = (input, value) => {
    setInputData({ ...inputData, [input]: value });
  };

  const attemptSignUp = async () => {
    try {
      USER.parse(inputData);
    } catch (error) {
      toast.error(error["issues"][0]["message"]);
      return;
    }
    try {
      const body = {
        ...inputData,
        username: meaningfulUserGenerator.meaningful(),
        metadata: {},
      };
      delete body["confirm"];
      setLoading(true);
      await signUp(body);
      dispatch(SetShowSignUp(false));
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Dialog
        id="SignUpDialog"
        open={showSignUp}
        onClose={handleClose}
        aria-labelledby="SignUp"
        aria-describedby="Proceed to SignUp"
        fullWidth
        maxWidth="sm"
      >
        <div className="d-flex w-100 flex-column flex-md-row">
          <div className="d-flex flex-column col-12 col-md-6 my-3">
            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
              {theme === "dark" ? (
                <BrandLogoLight style={{ width: "200px", height: "30px" }} />
              ) : (
                <BrandLogoDark style={{ width: "200px", height: "30px" }} />
              )}
            </div>
          </div>
          <div className="d-flex flex-column col-12 col-md-6 py-3 px-4">
            <DialogTitle id="SignUp-title" className="text-center">
              Sign Up!
            </DialogTitle>

            <TextField
              id="email"
              label="Email"
              placeholder="john.doe@somemail.com"
              variant="outlined"
              type="text"
              className="mb-4"
              value={inputData["email"]}
              onChange={(e) => handleInputDataChange("email", e.target.value)}
            />
            <TextField
              id="password"
              label="Password"
              variant="outlined"
              placeholder="*********"
              type="password"
              className="mb-4"
              value={inputData["password"]}
              onChange={(e) =>
                handleInputDataChange("password", e.target.value)
              }
            />
            <TextField
              id="confirm"
              label="Confirm Password"
              variant="outlined"
              placeholder="*********"
              type="password"
              className="mb-4"
              value={inputData["confirm"]}
              onChange={(e) => handleInputDataChange("confirm", e.target.value)}
            />
            <div className="w-100 d-flex align-items-center justify-content-end mt-4">
              <Button
                onClick={attemptSignUp}
                autoFocus
                disabled={loading}
                variant="outlined"
              >
                Sign Up
                {loading ? (
                  <>
                    &nbsp; &nbsp;
                    <CircularProgress size="1rem" />
                  </>
                ) : (
                  <></>
                )}
              </Button>
            </div>
            <div
              className="d-flex justify-content-start mt-4 align-items-center"
              style={{ fontSize: "0.8rem" }}
            >
              already registered?&nbsp;
              <Button
                onClick={() => {
                  dispatch(SetShowSignUp(false));
                  dispatch(SetShowSignIn(true));
                }}
                style={{ fontSize: "0.8rem", color: "var(--primary-color)" }}
              >
                Sign In
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};
export default SignUp;
