import axios from "axios";
import { POST_API_PATH } from "./api.constant";

export const submitPost = async (data) => {
  try {
    let authResult = await axios.post(POST_API_PATH.SUBMIT, data);
    if (authResult.status === 200) {
      return authResult.data.data;
    } else {
      throw new Error(authResult.data.error);
    }
  } catch (error) {
    throw error?.response?.data?.errors[0] || { message: "Some Error Occured" };
  }
};

export const getPost = async (postId) => {
  try {
    let authResult = await axios.get(POST_API_PATH.GET_POST(postId));
    if (authResult.status === 200) {
      return authResult.data.data;
    } else {
      throw new Error(authResult.data.error);
    }
  } catch (error) {
    throw error?.response?.data?.error || { message: "Some Error Occured" };
  }
};

export const reactToPost = async (postId, type) => {
  try {
    let authResult = await axios.put(POST_API_PATH.REACT(postId, type));
    if (authResult.status === 200) {
      return authResult.data.data;
    } else {
      throw new Error(authResult.data.error);
    }
  } catch (error) {
    throw error?.response?.data?.error || { message: "Some Error Occured" };
  }
};
