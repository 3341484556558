import axios from "axios";
import { AUTH_API_PATH } from "./api.constant";

export const login = async (data) => {
  try {
    let authResult = await axios.post(AUTH_API_PATH.LOGIN, data);
    if (authResult.status === 200) {
      return authResult.data.data;
    } else {
      throw new Error(authResult.data.error);
    }
  } catch (error) {
    throw error.response.data.errors[0];
  }
};

export const logout = async (data) => {
  try {
    let logoutResult = await axios.post(AUTH_API_PATH.LOGOUT, data);
    if (logoutResult.status === 200) {
      return logoutResult.data.data;
    } else {
      throw new Error(logoutResult.error);
    }
  } catch (error) {
    throw error.response.data.errors[0];
  }
};

export const register = async (data) => {
  try {
    let signUpResult = await axios.post(AUTH_API_PATH.SIGNUP, data);
    if (signUpResult.status === 200) {
      return signUpResult.data.data;
    } else {
      throw new Error(signUpResult.error);
    }
  } catch (error) {
    throw error.response.data.errors[0];
  }
};
