/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { getComments } from "../../API/comment.api";
import { Collapse, Skeleton } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import { TransitionGroup } from "react-transition-group";
import Comment from "./comment/Comment";

const Comments = ({ postId, commentsCount }) => {
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMorePage, setHasMorePage] = useState(false);

  useEffect(() => {
    loadComments();
  }, [postId, commentsCount]);

  const loadComments = async (pageNumber = 1) => {
    setComments([]);
    try {
      setPage(pageNumber);
      setLoading(true);
      const newCommentsData = await getComments(postId, pageNumber);
      const finalCommentData = [...comments, ...newCommentsData];
      setComments(finalCommentData);
      setHasMorePage(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const LOADING_COMMENT_SKELETON = (
    <>
      <TransitionGroup>
        <Collapse key={"pl-1"}>
          <Skeleton
            variant="rectangular"
            height={50}
            className="mb-3"
            sx={{ borderRadius: "5px" }}
          />
        </Collapse>
        <Collapse key={"pl-2"}>
          <Skeleton
            variant="rectangular"
            height={50}
            className="mb-3"
            key={"pl-2"}
            sx={{ borderRadius: "5px", opacity: "0.8" }}
          />
        </Collapse>
        <Collapse key={"pl-3"}>
          <Skeleton
            variant="rectangular"
            height={50}
            className="mb-3"
            key={"pl-3"}
            sx={{ borderRadius: "5px", opacity: "0.6" }}
          />
        </Collapse>
      </TransitionGroup>
    </>
  );

  return (
    <div className="w-100">
      <div className="d-flex">
        <div className="col-1 d-flex justify-content-center">
          <div
            style={{
              borderLeft: "3px solid var(--highlight-color)",
              width: "3px",
            }}
            className="h-100 pb-5"
          >
            &nbsp;
          </div>
        </div>
        <div className="flex-grow-1">
          <InfiniteScroll
            dataLength={comments.length}
            next={() => {
              loadComments(page + 1);
            }}
            hasMore={hasMorePage}
            className="position-relative"
            style={{ overflow: "reset" }}
            loader={LOADING_COMMENT_SKELETON}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>
                  {comments.length > 0 ? (
                    <div className="my-2">No More Comments!</div>
                  ) : (
                    <div className="my-2">
                      {loading ? "Loading!" : ""}
                    </div>
                  )}
                </b>
              </p>
            }
            scrollableTarget="body"
          >
            <TransitionGroup>
              {comments.map((comment, index) => (
                <Collapse key={comment["postId"] + index}>
                  <Comment
                    post={comment}
                    index={index}
                    key={comment["postId"] + index}
                  />
                </Collapse>
              ))}
            </TransitionGroup>
            {loading && comments.length === 0 ? (
              <>{LOADING_COMMENT_SKELETON}</>
            ) : (
              <></>
            )}
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

export default Comments;
