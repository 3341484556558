import React, { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Fab, Skeleton, SwipeableDrawer } from "@mui/material";
import HomeMenu from "./HomeMenu/HomeMenu";
import NewPost from "../../components/NewPost/NewPost";
import Feed from "./Feed/Feed";
import Groups from "./Groups/Groups";
import Settings from "./Settings/Settings";
import PostDetails from "./PostDetails/PostDetails";
import MenuIcon from "@mui/icons-material/Menu";

const Home = () => {
  const [homeMenuOpen, setHomeMenuOpen] = useState(false);

  return (
    <div className="d-flex mt-3">
      <SwipeableDrawer
        anchor={"bottom"}
        open={homeMenuOpen}
        onClose={() => setHomeMenuOpen(false)}
        onOpen={() => setHomeMenuOpen(true)}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <HomeMenu />
      </SwipeableDrawer>
      <div className="d-none d-md-block col-md-2">
        <HomeMenu />
      </div>
      <div
        className="position-fixed d-md-none"
        style={{ bottom: "1em", left: "1em", zIndex: "999" }}
      >
        <Fab
          color="secondary"
          aria-label="edit"
          onClick={() => setHomeMenuOpen(true)}
        >
          <MenuIcon />
        </Fab>
      </div>
      <div className="col-12 col-md-7 pt-2 px-0 px-md-4">
        <Routes>
          <Route
            path="/feed"
            element={
              <>
                <NewPost />
                <Feed />
              </>
            }
          />
          <Route path="/groups" element={<Groups />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/post/:postId" element={<PostDetails />} />
          <Route path="*" element={<Navigate to="/home/feed" />} />
        </Routes>
      </div>
      <div className="d-none d-md-flex flex-column col-md-3">
        <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
        <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
        <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
        <Skeleton variant="text" sx={{ fontSize: "3rem" }} />
      </div>
    </div>
  );
};

export default Home;
