/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useAuthContext } from "../../custom/providers/AuthProvider";
import UserIcon from "../UserIcon/UserIcon";
import MUIRichTextEditor from "mui-rte";
import { Button, LinearProgress } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { stateToHTML } from "draft-js-export-html";
import { submitPost } from "../../API/post.api";
import { submitComment } from "../../API/comment.api";
import { toast } from "react-toastify";
import RequireLogin from "../RequireLogin/RequireLogin";
import { ContentState, convertToRaw } from "draft-js";

const myTheme = createTheme({
  // Set up your custom MUI theme here
  overrides: {
    MuiIconButton: {
      root: {
        color: "red !important",
      },
    },
    MUIRichTextEditor: {
      container: {
        display: "flex",
        flexDirection: "column-reverse",
        paddingTop: "0",
      },
      root: {
        "& pre": {
          color: "#212121",
        },
      },
      editorContainer: {
        background: "var(--highlight-background)",
        borderRadius: "5px",
        padding: "10px",
      },
      editor: {
        maxHeight: "200px",
        overflow: "auto",
        borderRadius: "5px",
      },
      placeHolder: {
        paddingLeft: 10,
        width: "inherit",
        position: "static",
        color: "var(--color-lighter)",
      },
      anchorLink: {
        color: "#FFEB3B",
        textDecoration: "underline",
      },
    },
  },
});
const getEmptyValueForRTE = () => {
  const emptyState = ContentState.createFromText("");
  const resetValue = JSON.stringify(convertToRaw(emptyState));
  return resetValue;
};

const NewPost = ({ postId, posted }) => {
  const { isAuthenticated, user } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState(undefined);
  const [htmlContent, setHtmlContent] = useState(null);

  const attemptSubmitPost = async () => {
    try {
      setLoading(true);
      if (postId) {
        await submitComment(postId, { content: htmlContent });
      } else {
        await submitPost({ content: htmlContent });
      }
      if (posted) {
        posted();
      }
      toast.success("Post Live!");
      setContent(getEmptyValueForRTE());
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className="new-post w-100 px-3 px-md-3 pb-3 d-flex mb-3"
      style={{ borderBottom: "1px solid var(--highlight-background)" }}
    >
      <div className="col-2 col-md-1">
        {isAuthenticated ? (
          <div className="mt-2">
            <UserIcon
              username={
                user["tokenDecoded"]["username"] ||
                user["tokenDecoded"]["email"]
              }
              size={"30px"}
            />
          </div>
        ) : (
          <AccountCircleIcon
            className="user-logo mt-2"
            sx={{ fontSize: "30px" }}
          />
        )}
      </div>
      <div className="col-10 col-md-11 d-flex flex-column position-relative">
        <ThemeProvider theme={myTheme}>
          <MUIRichTextEditor
            label="Type something here..."
            controls={[
              "bold",
              "italic",
              "underline",
              "strikethrough",
              "link",
              "numberList",
              "bulletList",
              "code",
            ]}
            defaultValue={content}
            disabled={loading}
            toolbar={true}
            toolbarButtonSize={"small"}
            inlineToolbar={false}
            onChange={(event) => {
              // setContent(event.getCurrentContent());
              setHtmlContent(stateToHTML(event.getCurrentContent()));
            }}
          />
        </ThemeProvider>
        <div className="w-100 mt-2 d-flex justify-content-end mb-2">
          <RequireLogin>
            <Button
              variant="text"
              onClick={attemptSubmitPost}
              disabled={loading}
            >
              Post&nbsp;
              <SendIcon sx={{ fontSize: "1.2rem" }} />
            </Button>
          </RequireLogin>
        </div>
        {loading ? (
          <LinearProgress
            className="w-100 position-absolute"
            style={{ bottom: 0 }}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default NewPost;
