import { toast } from "react-toastify";
import { login, logout, register } from "../../API/auth.api";
import jwt from "jwt-decode";
import useLocalStorage from "use-local-storage";
import axios from "axios";

export const useAuth = () => {
  const [isAuthenticated, setAuthenticated] = useLocalStorage(
    "isAuthenticated",
    false
  );
  const [user, setUser] = useLocalStorage("user", null);

  const signIn = async (data) => {
    try {
      let authresult = await login(data);
      axios.defaults.headers.common["Authorization"] = "Bearer " + authresult.token;
      authresult["tokenDecoded"] = jwt(authresult.token); // decode your token here
      setUser(authresult);
      setAuthenticated(true);
      toast.success("Login Successfull");
    } catch (err) {
      setUser(null);
      setAuthenticated(false);
      throw err;
    }
  };

  const signUp = async (data) => {
    try {
      let authresult = await register(data);
      authresult["tokenDecoded"] = jwt(authresult.token); // decode your token here
      setUser(authresult);
      setAuthenticated(true);
      toast.success("Sign Up and Sign In Successfull");
    } catch (err) {
      toast.error("An Error Occuered");
    }
  };

  const signOut = (data) => {
    axios.defaults.headers.common["Authorization"] = null;
    if (data !== null) {
      logout(data);
    }
    toast.success("Logged out!");
    setAuthenticated(false);
    setUser(null);
  };

  const checkExpiry = () => {
    if (user !== null) {
      if (new Date() > new Date(user["tokenDecoded"]["exp"] * 1000)) {
        setAuthenticated(false);
        setUser(null);
        toast.info("Session Expired, logged out!");
      } else {
        console.log(
          "Setting JWT Expiry for ",
          (new Date(user["tokenDecoded"]["exp"] * 1000) - new Date()) / 60000
        );
        setTimeout(() => {
          setAuthenticated(false);
          setUser(null);
          toast.info("Session Expired, logged out!");
        }, new Date(user["tokenDecoded"]["exp"] * 1000) - new Date());
      }
    }
  };

  return { isAuthenticated, user, signIn, signUp, signOut, checkExpiry };
};
